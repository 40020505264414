<template>
  <v-card>
    <v-card class="pa-3">
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>
          Novo Manifesto
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          class="mx-2"
          small
          fab
          color="light-green accent-4"
          @click="onNovoManifesto"
        >
          <v-icon class="white--text">
            mdi-plus
          </v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          small
          fab
          color="amber"
          @click="onSair"
        >
          <v-icon class="white--text">
            mdi-exit-to-app
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row dense>
          <v-col
            cols="8"
          >
            <v-select
              v-model="CodigoOperacao"
              :items="itensManifesto"
              item-text="descricao"
              item-value="id"
              label="Tipo Operação"
              dense
              solo
              hide-details
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col
            cols="10"
          >
            <v-text-field
              v-model="txtChave"
              label="Chave de Acesso"
              solo
              dense
              hide-details
            />
          </v-col>
          <v-col
            cols="2"
          >
            <div class="my-1">
              <v-btn
                color="primary"
                fab
                x-small
                dark
                @click="onAddChave"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-virtual-scroll
              :bench="0"
              height="120"
              item-height="50"
              :items="listaChaves"
            >
              <template v-slot:default="{ index, item }">
                <v-list-item :key="index">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      fab
                      x-small
                      depressed
                      color="red"
                      @click="removerItem(index)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-divider />
              </template>
            </v-virtual-scroll>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-card>
</template>
<script>
  import manifestacaoDestRep from '../../../../../components/services/manifestacaoDestinatarioRepository'
  export default {
    props: {
      loja: {
        type: Number,
        required: true,
      },
    },
    data () {
      return {
        txtChave: '',
        listaChaves: [],
        headersManifesto: [
          {
            text: 'Chave NFe',
            align: 'start',
            value: 'chaveNFe',
            width: 200,
          },
        ],
        itensManifesto: [
          { id: 0, descricao: 'Ciência da Operação' },
          { id: 1, descricao: 'Confirmação da Operação' },
          { id: 2, descricao: 'Desconhecimento da Operação' },
          { id: 3, descricao: 'Operação não Realizada' },
        ],
        CodigoOperacao: 0,
      }
    },
    methods: {
      async onNovoManifesto () {
        if (this.onValidarDados()) {
          this.$store.commit('setSplashScreen', true)
          var dadosSalvar = {
            listaTxChaveAcesso: this.listaChaves,
            tpOperacao: this.CodigoOperacao,
            cdLoja: this.loja,
          }
          await manifestacaoDestRep.onPost(dadosSalvar).then(response => {
            this.$store.commit('setSplashScreen', false)
            if (response.status === 201) {
              this.$swal({
                icon: 'success',
                text: 'Incluído com sucesso!',
              })
            } else if (response.status === 409) {
              this.$swal({
                icon: 'warning',
                text: 'As seguintes chaves não foram incluídas:' + response.data,
              })
            }
          }).catch(error => {
            this.$store.commit('setSplashScreen', false)
            if (error.response.status === 409) {
              this.$swal({
                icon: 'warning',
                text: error.response.data,
              })
            } else {
              this.$swal({
                icon: 'error',
                text: error,
              })
            }
          })
        }
      },
      onValidarDados () {
        if (this.listaChaves.length === 0) {
          this.$swal({
            icon: 'warning',
            text: 'Informe a chave(s) de acesso',
          })
          return false
        } else {
          return true
        }
      },
      onAddChave () {
        if (this.txtChave !== '') {
          if (!this.validarItemExiste(this.txtChave)) {
            this.listaChaves.push(this.txtChave)
            this.txtChave = ''
          } else {
            this.$swal({
              icon: 'warning',
              text: 'Chave já adicionada',
            })
          }
        } else {
          this.$swal({
            icon: 'warning',
            text: 'Informe uma chave valída',
          })
        }
      },
      validarItemExiste (chave) {
        var exists = this.listaChaves.some(function (item) {
          return item === chave
        })
        return exists
      },
      onSair () {
        this.$emit('onSair')
      },

      removerItem (index) {
        this.listaChaves.splice(index, 1)
      },
    },
  }
</script>
