<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-btn
      depressed
      color="primary"
      @click="onNovoManifesto"
    >
      Novo Manifesto
    </v-btn>
    <v-card class="mt-1">
      <v-tabs
        v-model="tab"
        bg-color="primary"
      >
        <v-tab
          href="#tabSemRetorno"
          @click="onGetListaSemRetorno"
        >
          Sem Retorno
        </v-tab>
        <v-tab
          href="#tabTodos"
          @click="onGetListaTodos"
        >
          Todos
        </v-tab>
      </v-tabs>

      <v-card-text>
        <v-tabs-items v-model="tab">
          <v-tab-item
            value="tabSemRetorno"
            class="ma-2"
          >
            <v-data-table
              dense
              :headers="headers"
              :items="listaManifestacaoSemRetorno"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon
                  x-larger
                  class="mr-2"
                  @click="onModalManifestacao(item)"
                >
                  mdi-magnify
                </v-icon>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item
            value="tabTodos"
            class="ma-2"
          >
            <v-data-table
              dense
              :headers="headersTodos"
              :items="listaManifestacao"
            />
            <!-- <template v-slot:item.actions="{ item }">
                <v-icon
                  x-larger
                  class="mr-2"
                  @click="onModalManifestacao(item)"
                >
                  mdi-file-search
                </v-icon>
              </template>
            </v-data-table> -->
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="dialogLoja"
      max-width="300"
      persistent
    >
      <v-card>
        <v-toolbar
          color="primary"
          dark
        >
          Selecione a Loja
        </v-toolbar>
        <v-card-text>
          <componenteLoja
            :id="loja"
            dense
            :flparamentrovenda="false"
            @onDadosLoja="onDadosLoja"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogNovoManifesto"
      max-width="600"
      persistent
    >
      <modalNovo
        v-if="dialogNovoManifesto"
        :loja="loja"
        @onSair="onSair"
      />
    </v-dialog>
  </v-container>
</template>
<script>
  import manifestacaoDestinatarioRep from '../../../../components/services/manifestacaoDestinatarioRepository'
  import componenteLoja from '../../../componentes/componente-loja.vue'
  import modalNovo from '../manifestacaodestinatario/modal/novamanifesto.vue'
  export default {
    components: {
      componenteLoja,
      modalNovo,
    },
    data () {
      return {
        headers: [
          {
            text: 'Fornecedor',
            align: 'start',
            value: 'nmFornec',
            width: 200,
          },
          {
            text: 'Chave',
            value: 'txChAcessoNFe',
            width: 200,
          },
          {
            text: 'Operação',
            value: 'descOperacao',
            width: 200,
          },
          {
            text: 'Data Hora',
            value: 'dataEventoFormatada',
            width: 200,
          },
          { text: '', value: 'actions', width: 400, sortable: false, align: 'center' },
        ],
        headersTodos: [
          {
            text: 'Fornecedor',
            align: 'start',
            value: 'nmFornec',
            width: 200,
          },
          {
            text: 'Chave',
            value: 'txChAcessoNFe',
            width: 200,
          },
          {
            text: 'Operação',
            value: 'descOperacao',
            width: 200,
          },
          {
            text: 'Data Hora',
            value: 'dataEventoFormatada',
            width: 200,
          },
          {
            text: 'Retorno',
            value: 'cdRetorno',
            width: 100,
          },
          // { text: '', value: 'actions', width: 400, sortable: false, align: 'center' },
        ],
        tab: 'tabSemRetorno',
        listaManifestacao: [],
        listaManifestacaoSemRetorno: [],
        loja: 0,
        dadosLoja: {},
        dialogLoja: true,
        dialogNovoManifesto: false,
      }
    },
    methods: {
      async onGetListaSemRetorno () {
        this.$store.commit('setSplashScreen', true)
        await manifestacaoDestinatarioRep.getListaSemRetorno(this.dadosLoja.cdLoja).then((resp) => {
          this.listaManifestacaoSemRetorno = resp.data
          this.$store.commit('setSplashScreen', false)
        }).catch(Error => {
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'error',
            text: 'Erro ao Consultar os Dados',
            confirmButtonText: 'OK',
          }).then((result) => {
            if (result.isConfirmed) return false
          })
        })
      },
      async onGetListaTodos () {
        await manifestacaoDestinatarioRep.onGetListaTodos().then((resp) => {
          this.listaManifestacao = resp.data
        })
      },
      onNovoManifesto () {
        this.dialogNovoManifesto = true
      },
      onDadosLoja (dados) {
        this.dadosLoja = dados
        this.loja = dados.cdLoja
        this.dialogLoja = false
        this.onGetListaSemRetorno()
      },
      onSair () {
        this.dialogNovoManifesto = false
      },
    },
  }
</script>
