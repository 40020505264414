import Repository from './repository'

const resource = '/manifestodestinatario'

export default {

  async getListaSemRetorno (loja) {
    return Repository.get(`${resource}?cdLoja=${loja}`)
  },
  async onGetListaTodos () {
    return Repository.get(`${resource}/pagina`)
  },
  async onPost (dados) {
    return Repository.post(`${resource}`, dados)
  },
}
